(function () {
    const isLocal = (window.location.protocol.toLowerCase() === 'file:' || window.location.hostname === 'localhost');
    var lcoalResult = function(cb, err) {

        var systems = ["國小", "國中", "高中"];
        var grades = ["國小先修", "國一", "國二", "國三", "高一", "高二", "高三"];
        var volumes = ["上學期", "下學期", "總複習"];
        var categories = ["組合包", "e名師", "e評量"];
        var products = ["國文", "英文", "數學", "自然", "社會"];
        var covers = ["#{cdn}/web/courseintro/國一上課程圖-資優國文.png", "#{cdn}/web/courseintro/國一上課程圖-英文.png", "#{cdn}/web/courseintro/國一上課程圖-資優數學.png", "#{cdn}/web/courseintro/國一上課程圖-自然.png", "#{cdn}/web/courseintro/國一上課程圖-社會.png"];
        var teams = ["柳吟國文團隊", "林熹英文團隊", "傅壹數學團隊"];
        var teachers = ["柳吟", "陳甘霖", "林熹", "藍傑", "春暉", "謝豪", "李天豪"];
        var labels = ["108上", "108下", "108"];

        var system = systems.map(function (v, i) {
            return { id: 'system_' + i, order: i, name: v };
        });

        var grade = grades.map(function (v, i) {
            return { id: 'grade_' + i, order: i, name: v };
        });

        var volume = volumes.map(function (v, i) {
            return { id: 'volume_' + i, order: i, name: v };
        });

        var category = categories.map(function (v, i) {
            return { id: 'category_' + i, order: i, name: v };
        });

        var team = teams.map(function (v, i) {
            return { id: 'team_' + i, order: i, name: v };
        });

        var teacher = teachers.map(function (v, i) {
            return { id: 'teacher_' + i, order: i, name: v };
        });

        var product = products.map(function (p, pi) {
            var s = system[1];
            var g = grade[1];
            var v = volume[1];
            var c = category[1];
            seed = Math.round(Math.random() * (1 << 30));
            return {
                id: 'G1_V1_C1_P' + pi,
                order: pi,
                name: g.name + v.name + p + c.name,
                cover: covers[pi],
                team: [team[seed % 3].id],
                teacher: seed % 2 == 0 ? [teacher[seed % 7].id, teacher[(seed + 1) % 7].id] : [teacher[seed % 7].id],
                origin: 999999,
                system: [s.id],
                grade: [g.id],
                volume: [v.id],
                category: [c.id],
                isNew: seed % 7 == 0,
                star: seed % 9 == 0,
                label: labels[pi % labels.length],
                sell: 888888,
                bought: seed % 11 == 0
            };
        });

        var result = {
            system: system,
            grade: grade,
            volume: volume,
            category: category,
            team: team,
            teacher: teacher,
            product: product
        };

        setTimeout(function(){
            cb(result);
        }, 2000);

    };

    var jsonAjax = function(url, cb, err){
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == XMLHttpRequest.DONE && this.status == 200) {
                cb(JSON.parse(this.responseText));
           }
        };
        xhttp.open("GET", url, true);
        xhttp.send();

    };

    var wwwReslut = function(cb, err) {
        var stars, banner = null;
        var checkCompleted = function(){
            if(stars && banner){
                stars.banner = banner;
                cb(stars);
            }
        };
        jsonAjax("/online-showcase/stars", function(starsData){
            stars = starsData;
            checkCompleted();
        });
        jsonAjax("/IndexBanners", function(bannersData){
            if(bannersData.success){
                banner = bannersData.result;
            }
            checkCompleted();
        });
    };

    var bannerLocalResult = function(cb, err){
        var banner = [{ "_id": "5a6588a0e4b0ec6b759801f5", "href": "https://www.ehanlin.com.tw/courses_map.html", "order": 1, "enabled": true }, { "_id": "5a375443e4b0897c96c86a62", "href": "https://www.ehanlin.com.tw/type/ONLINE/category/%E3%80%90e%E5%90%8D%E5%B8%AB%E3%80%91%E5%9C%8B%E4%B8%89%E4%B8%8B%E5%AD%B8%E6%9C%9F/SalesPlans.html", "order": 5, "enabled": true }, { "_id": "59b65b64e4b0731c320a26b1", "href": "https://www.ehanlin.com.tw/type/ONLINE/id/y106_g10_s2_so_training_et/SalesPlans.html", "order": 10, "enabled": true }, { "_id": "599b93f7e4b0731c320a26b0", "href": "http://www.ehanlin.com.tw/event/richman-questionnaire/main.html", "order": 11, "enabled": true }, { "_id": "59913f71e4b0731c320a26af", "href": "https://www.ehanlin.com.tw/type/ONLINE/category/%E3%80%90e%E5%90%8D%E5%B8%AB%E3%80%91%E5%9C%8B%E5%B0%8F%E8%B3%87%E5%84%AA/SalesPlans.html", "order": 12, "enabled": true }];
        setTimeout(function(){
            cb(banner);
        }, 2000);
    };

    var bannerWwwResult = function(cb, err){
        jsonAjax("/ads/indexBanners?category=index", function(bannersData){
            if(bannersData.length!=0){
                cb(bannersData);
            }
        });
    };

    var storyLocalResult = function(cb, err){
        loadJSON("./js/storys.json",
            function(data) {
                cb(data);
            },
            function(xhr) { console.error(xhr); }
        );
    };

    function loadJSON(path, success, error){
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function()
        {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    if (success)
                        success(JSON.parse(xhr.responseText));
                } else {
                    if (error)
                        error(xhr);
                }
            }
        };
        xhr.open("GET", path, true);
        xhr.send();
    }

    var storyWwwResult = function(cb, err){
        jsonAjax("/label/index/Story.json", function(storyData){
            if(storyData.success){
                cb(storyData.result);
            }
        });
    };

    // 開放給頁面使用的介面
    if (window.eHanlin === undefined || window.eHanlin === null) {
        window.eHanlin = {};
    }

    if (window.eHanlin.dataprovider === undefined || window.eHanlin.dataprovider === null) {
        window.eHanlin.dataprovider = {};
    }

    window.eHanlin.dataprovider.index = {
        stars: function(cb, err) {
            isLocal ? lcoalResult(cb, err) : wwwReslut(cb, err);
        },
        banner: function(cb, err) {
            isLocal ? bannerLocalResult(cb, err) : bannerWwwResult(cb, err);
        },
        story: function(cb, err) {
            // isLocal ? storyLocalResult(cb, err) : storyWwwResult(cb, err);
            storyLocalResult(cb, err)
        }
    }
})();
